<template>
  <div>
    <hero :src=randomJumbotron ></hero>
    <contents :sponsors="sponsors" />
  </div>
</template>

<script>
import {fetchData, query_SPONSORIMAGES} from "@/utils/api"
import i18n from "@/plugins/i18n";

export default {
        components: {
            Hero: () => import('@/components/home/Hero'),
            Contents: () => import('@/components/home/Contents')
        },
        data: () => ({
            randomJumbotron: require('@/assets/img/swimstart.jpg'),
            sponsors:[]
        }),
      methods: {
        getSponsors: async () => {
          const query = query_SPONSORIMAGES(i18n.locale)
          return await fetchData(query, 'sponsorCollection')
        }
      },
        mounted(){
            this.$store.dispatch('app/setLink', 0)
        },
      async created() {
        this.sponsors = await this.getSponsors()
      }
    }
</script>
